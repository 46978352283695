import { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { blackColor, borderColor, whiteColor } from "../../../../assets/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  createReplyTicketRequest,
  getTicketDetailsRequest,
} from "../../../../redux/actions";
import { useParams } from "react-router-dom";
import FormGenerator from "../../../../components/FormGenerator";
import { formItems } from "./formItems";
import {
  CREATE_REPLY_TICKET_STARTED,
  GET_TICKET_DETAILS_STARTED,
} from "../../../../redux/types";
import { getDateByLocale } from "../../../../utils/helpers";
import { SupportIcon } from "../../../../assets/SVGs";
import AttachmentIcon from "@mui/icons-material/Attachment";
// --------------------------------------------------------------------------------
const Ticket = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { rType, selectedTicket } = useSelector((state) => state.ticketReducer);
  const { profileSrc } = useSelector((state) => state.authReducer);
  const loading =
    rType === GET_TICKET_DETAILS_STARTED ||
    rType === CREATE_REPLY_TICKET_STARTED;

  useEffect(() => {
    dispatch(getTicketDetailsRequest({ ticket_id: id }));
  }, []);

  const handleDownload = (base64String) => {
    const mimeType = base64String.match(/^data:(.*);base64,/)[1]; // e.g., "image/png"
    const fileExtension = mimeType.split("/")[1]; // e.g., "png"

    const timestamp = Date.now(); // Current timestamp
    const fileName = `file_${timestamp}.${fileExtension}`;

    const link = document.createElement("a");
    link.href = base64String;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      <Box paddingY={5} marginBottom={3}>
        <Typography variant="h4" textAlign="center">
          تیکت
        </Typography>
      </Box>
      <Box sx={{ borderBottom: `1px solid ${borderColor}` }}>
        <Typography gutterBottom>
          موضوع تیکت: {selectedTicket[0]?.subject}
        </Typography>
      </Box>
      <Grid container sx={{ flexDirection: "column", p: 5 }}>
        {selectedTicket?.map((ticket) =>
          ticket.text === null ? null : ticket.admin_id ? (
            <Grid item xs={12} lg={6} alignSelf="flex-end" sx={{ mb: 3 }}>
              <Box
                sx={{
                  bgcolor: whiteColor,
                  color: blackColor,
                  p: 2,
                  borderRadius: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", mr: 5 }}>
                    <SupportIcon style={{ width: 24 }} />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      پشتیبانی
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    {getDateByLocale(ticket.created_at)}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${ticket.text}`,
                      }}
                    ></div>
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  {ticket.attachment ? (
                    <Button
                      sx={{ mt: 5 }}
                      variant="contained"
                      startIcon={<AttachmentIcon />}
                      onClick={() => handleDownload(ticket.attachment)}
                    >
                      دانلود فایل ضمیمه
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item lg={4} sx={{ mb: 3 }}>
              <Box
                sx={{
                  bgcolor: "#33414F",
                  p: 2,
                  borderRadius: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Avatar src={profileSrc} sx={{ width: 24, height: 24 }} />
                    <Typography sx={{ ml: 1 }}>من</Typography>
                  </Box>
                  <Typography variant="caption">
                    {getDateByLocale(ticket.created_at)}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2">{ticket.text}</Typography>
                  {ticket.attachment ? (
                    <Button
                      sx={{ mt: 5 }}
                      variant="contained"
                      startIcon={<AttachmentIcon />}
                      onClick={() => handleDownload(ticket.attachment)}
                    >
                      دانلود فایل ضمیمه
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </Grid>
          )
        )}
      </Grid>
      <Box>
        <FormGenerator
          formItems={formItems}
          onSubmit={({ text, attachment }, clearForm) => {
            let formData = new FormData();

            formData.append("ticket_id", id);
            formData.append("text", text);
            formData.append("source", "Website");
            if (attachment) formData.append("attachment", attachment);

            dispatch(createReplyTicketRequest(formData));
            clearForm();
          }}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Ticket;
